import React from 'react';
import PolicySection from '@components/Policy/section/PolicySection';
import Layout from '@layouts/Layout';

const Policy = () => {
  return (
    <Layout>
      <PolicySection />
    </Layout>
  );
};

export default Policy;